import {
  EnvironmentInjector,
  inject,
  Injectable,
  runInInjectionContext,
} from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Observable, from, of } from 'rxjs';
import { StaticActions } from './static.actions';
import {
  Firestore,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  DocumentData,
  onSnapshot,
  Unsubscribe,
} from '@angular/fire/firestore';
import {
  Carrier,
  PermanentTag,
  StaticPermanentTagsContainer,
} from 'src/app/shared/models';
import { Action } from '@ngrx/store';

@Injectable()
export class StaticEffects {
  private environmentInjector = inject(EnvironmentInjector);

  loadStaticCarriers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StaticActions.loadStaticCarriers),
      mergeMap(() =>
        from(
          getDocs(collection(this.firestore, 'static/carrier/carriers'))
        ).pipe(
          map(snapshot => {
            const carriers = snapshot.docs.map(doc =>
              Carrier.fromJSON({
                ...doc.data(),
                id: doc.id,
              })
            );
            return StaticActions.loadStaticCarriersSuccess({ carriers });
          }),
          catchError(error =>
            of(StaticActions.loadStaticCarriersFailure({ error }))
          )
        )
      )
    );
  });

  private staticTagsSubscription: { [key: string]: Unsubscribe } = {};

  loadStaticTags$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StaticActions.loadStaticTags),
      mergeMap(
        ({ agencyId }) =>
          new Observable<Action>(subscriber => {
            if (this.staticTagsSubscription[agencyId]) {
              return;
            }
            runInInjectionContext(this.environmentInjector, () => {
              this.staticTagsSubscription[agencyId] = onSnapshot(
                doc(
                  this.firestore,
                  `agencies/${agencyId}/static/permanentTags`
                ),
                doc => {
                  const data = doc.data();

                  const tags: PermanentTag[] = [];

                  if (data && data['byId']) {
                    for (const [id, tag] of Object.entries(
                      data['byId'] || {}
                    )) {
                      tags.push(
                        PermanentTag.fromJSON({
                          ...(tag as object),
                          id,
                        })
                      );
                    }
                  }

                  subscriber.next(
                    StaticActions.loadStaticTagsSuccess({
                      agencyId,
                      tags,
                    })
                  );
                },
                error =>
                  subscriber.error(
                    StaticActions.loadStaticTagsFailure({ error })
                  )
              );
            });
            return () => this.staticTagsSubscription[agencyId]();
          })
      )
    );
  });

  updateStaticTag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StaticActions.updateStaticTag),
      mergeMap(async ({ agencyId, tag }) => {
        try {
          const docRef = doc(
            this.firestore,
            `agencies/${agencyId}/static/permanentTags`
          );
          await updateDoc(docRef, {
            [`byId.${tag.id}`]: PermanentTag.toJSON(tag),
          } as DocumentData);
          return StaticActions.updateStaticTagSuccess({
            agencyId,
            tag,
          });
        } catch (error) {
          return StaticActions.updateStaticTagFailure({
            error: error as Error,
          });
        }
      })
    );
  });

  addStaticTag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StaticActions.addStaticTag),
      mergeMap(async ({ agencyId, tag }) => {
        try {
          const docRef = doc(
            this.firestore,
            `agencies/${agencyId}/static/permanentTags`
          );

          const id = await this.generateTagId(agencyId);

          const newTag = {
            ...(PermanentTag.toJSON(tag) as object),
            id,
          };

          await setDoc(
            docRef,
            {
              byId: { [newTag.id]: newTag },
            },
            { merge: true }
          );
          return StaticActions.addStaticTagSuccess({
            agencyId,
            tag: newTag,
          });
        } catch (error) {
          return StaticActions.addStaticTagFailure({
            error: error as Error,
          });
        }
      })
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore
  ) {}

  async generateTagId(agencyId: string) {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const generateId = (agencyId: string) => {
      let result = '';
      for (let i = 0; i < 4; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return `${agencyId}#-#${result}`;
    };

    const docRef = doc(
      this.firestore,
      `agencies/${agencyId}/static/permanentTags`
    );
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      return generateId(agencyId);
    }

    const data = StaticPermanentTagsContainer.fromJSON(docSnap.data());
    const existingTags = data?.byId || {};

    let newId = generateId(agencyId);
    let count = 0;
    while (existingTags[newId]) {
      newId = generateId(agencyId);
      count++;
      if (count > 100) {
        throw new Error('Failed to generate a unique tag ID');
      }
    }

    return newId;
  }
}
