import {
  ApplicationConfig,
  ErrorHandler,
  PLATFORM_ID,
  inject,
  isDevMode,
  provideAppInitializer,
} from '@angular/core';
import { Router, provideRouter, withInMemoryScrolling } from '@angular/router';
import { routes } from './app-routing.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient } from '@angular/common/http';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  initializeAppCheck,
  provideAppCheck,
  ReCaptchaEnterpriseProvider,
} from '@angular/fire/app-check';
import { environment } from 'src/environments/environment';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideServiceWorker } from '@angular/service-worker';
import { createErrorHandler, TraceService } from '@sentry/angular';
import { provideToastr } from 'ngx-toastr';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { isPlatformServer } from '@angular/common';
import * as fromAgency from './shared/store/agency/+state/agency.reducer';
import { AgencyEffects } from './shared/store/agency/+state/agency.effects';
import * as fromAgent from './shared/store/agent/+state/agent.reducer';
import { AgentEffects } from './shared/store/agent/+state/agent.effects';
import * as fromAuthority from './shared/store/authority/+state/authority.reducer';
import { AuthorityEffects } from './shared/store/authority/+state/authority.effects';
import * as fromCarrier from './shared/store/carrier/+state/carrier.reducer';
import { CarrierEffects } from './shared/store/carrier/+state/carrier.effects';
import * as fromConsumer from './shared/store/consumer/+state/consumer.reducer';
import { ConsumerEffects } from './shared/store/consumer/+state/consumer.effects';
import * as fromEmployee from './shared/store/employee/+state/employee.reducer';
import { EmployeeEffects } from './shared/store/employee/+state/employee.effects';
import * as fromEnrollmentCompat from './shared/store/enrollment-compat/+state/enrollment-compat.reducer';
import { EnrollmentCompatEffects } from './shared/store/enrollment-compat/+state/enrollment-compat.effects';
import * as fromEnrollmentSunfire from './shared/store/enrollment-sunfire/+state/enrollment-sunfire.reducer';
import { EnrollmentSunfireEffects } from './shared/store/enrollment-sunfire/+state/enrollment-sunfire.effects';
import * as fromEnrollment from './shared/store/enrollment/+state/enrollment.reducer';
import { EnrollmentEffects } from './shared/store/enrollment/+state/enrollment.effects';
import * as fromLeadSeller from './shared/store/lead-seller/+state/lead-seller.reducer';
import { LeadSellerEffects } from './shared/store/lead-seller/+state/lead-seller.effects';
import * as fromPermissionGranted from './shared/store/permission-granted/+state/permission-granted.reducer';
import { PermissionGrantedEffects } from './shared/store/permission-granted/+state/permission-granted.effects';
import * as fromPermission from './shared/store/permission/+state/permission.reducer';
import { PermissionEffects } from './shared/store/permission/+state/permission.effects';
import * as fromPublisher from './shared/store/publisher/+state/publisher.reducer';
import { PublisherEffects } from './shared/store/publisher/+state/publisher.effects';
import * as fromSkill from './shared/store/skill/+state/skill.reducer';
import { SkillEffects } from './shared/store/skill/+state/skill.effects';
import * as fromStatic from './shared/store/static/+state/static.reducer';
import { StaticEffects } from './shared/store/static/+state/static.effects';

const provideSentryErrorHandler = (opts = {}) => {
  return {
    provide: ErrorHandler,
    useValue: createErrorHandler(opts),
  };
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled',
      })
    ),

    provideAnimations(),
    provideHttpClient(),

    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions(undefined, 'us-east1')),
    provideMessaging(() => getMessaging()),
    providePerformance(() => getPerformance()),
    provideStorage(() => getStorage()),
    //@ts-expect-error - Initialize only on client
    provideAppCheck(() => {
      const platformId = inject(PLATFORM_ID);
      if (isPlatformServer(platformId)) {
        return;
      }
      const provider = new ReCaptchaEnterpriseProvider(
        environment.firebase.appCheckRecaptchaSiteKey
      );
      return initializeAppCheck(undefined, {
        provider,
        isTokenAutoRefreshEnabled: true,
      });
    }),
    provideStore({
      [fromAgency.agencyFeatureKey]: fromAgency.reducer,
      [fromAgent.agentFeatureKey]: fromAgent.reducer,
      [fromAuthority.authorityFeatureKey]: fromAuthority.reducer,
      [fromCarrier.carrierFeatureKey]: fromCarrier.reducer,
      [fromConsumer.consumerFeatureKey]: fromConsumer.reducer,
      [fromEmployee.employeeFeatureKey]: fromEmployee.reducer,
      [fromEnrollment.enrollmentFeatureKey]: fromEnrollment.reducer,
      [fromEnrollmentCompat.enrollmentCompatFeatureKey]:
        fromEnrollmentCompat.reducer,
      [fromEnrollmentSunfire.enrollmentSunfireFeatureKey]:
        fromEnrollmentSunfire.reducer,
      [fromLeadSeller.leadsellerFeatureKey]: fromLeadSeller.reducer,
      [fromPermission.permissionFeatureKey]: fromPermission.reducer,
      [fromPermissionGranted.permissionGrantedFeatureKey]:
        fromPermissionGranted.reducer,
      [fromPublisher.publisherFeatureKey]: fromPublisher.reducer,
      [fromSkill.skillFeatureKey]: fromSkill.reducer,
      [fromStatic.staticFeatureKey]: fromStatic.reducer,
    }),
    provideEffects([
      AgencyEffects,
      AgentEffects,
      AuthorityEffects,
      CarrierEffects,
      ConsumerEffects,
      EmployeeEffects,
      EnrollmentEffects,
      EnrollmentCompatEffects,
      EnrollmentSunfireEffects,
      LeadSellerEffects,
      PermissionEffects,
      PermissionGrantedEffects,
      PublisherEffects,
      SkillEffects,
      StaticEffects,
    ]),
    !environment.production ? provideStoreDevtools() : [],
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideSentryErrorHandler({
      showDialog: false,
    }),
    {
      provide: TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      inject(TraceService);
    }),
    provideToastr(),
    AuthGuard,
  ],
};
