import {
  EnvironmentInjector,
  inject,
  Injectable,
  runInInjectionContext,
} from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { SkillActions } from './skill.actions';
import {
  Firestore,
  Unsubscribe,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { Skill } from '../../../models';
import { Action } from '@ngrx/store';

@Injectable()
export class SkillEffects {
  private environmentInjector = inject(EnvironmentInjector);

  loadSkills$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkillActions.loadSkills),
      switchMap(({ agencyId }) => {
        return new Observable<Action>(subscriber => {
          let unsubscribe: Unsubscribe | undefined;
          runInInjectionContext(this.environmentInjector, () => {
            unsubscribe = onSnapshot(
              collection(this.firestore, 'agencies', agencyId, 'skills'),
              snapshot => {
                const skills = snapshot.docs.map(doc =>
                  Skill.fromJSON({ ...doc.data(), id: doc.id })
                );
                subscriber.next(SkillActions.loadSkillsSuccess({ skills }));
              },
              error => {
                subscriber.next(SkillActions.loadSkillsFailure({ error }));
              }
            );
          });

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError(error =>
            of({ type: '[Skill API] Load Skills Error', error })
          )
        );
      })
    );
  });

  loadSkill$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkillActions.loadSkill),
      switchMap(({ skillId, agencyId }) => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'agencies', agencyId, 'skills', skillId),
            snapshot => {
              const skill = Skill.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
              });
              subscriber.next(SkillActions.loadSkillSuccess({ skill }));
            },
            error => {
              subscriber.next(SkillActions.loadSkillFailure({ error }));
            }
          );
          return unsubscribe;
        });
      })
    );
  });

  addSkill$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkillActions.addSkill),
      mergeMap(async ({ agencyId, skill }) => {
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'agencies', agencyId, 'skills'),
            Skill.toJSON(skill as Skill)
          );
          return SkillActions.addSkillSuccess({
            skill: Skill.fromJSON({ ...skill, id: docRef.id }),
          }); // return new skill with id
        } catch (error) {
          return SkillActions.addSkillFailure({ error: error as Error });
        }
      })
    );
  });

  removeSkill$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkillActions.removeSkill),
      mergeMap(async ({ agencyId, skillId }) => {
        try {
          await deleteDoc(
            doc(this.firestore, 'agencies', agencyId, 'skills', skillId)
          );
          return SkillActions.removeSkillSuccess({ skillId }); // return removed skill's id
        } catch (error) {
          return SkillActions.removeSkillFailure({ error: error as Error });
        }
      })
    );
  });

  updateSkill$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkillActions.updateSkill),
      mergeMap(async ({ agencyId, skillId, skill }) => {
        try {
          const data = Skill.toJSON(skill) as Partial<unknown>;
          console.log('data', { data, agencyId, skillId });
          await setDoc(
            doc(this.firestore, 'agencies', agencyId, 'skills', skillId),
            data,
            {
              merge: true,
            }
          );
          return SkillActions.updateSkillSuccess({
            agencyId,
            skillId,
            skill,
          }); // return updated skill's id and changes
        } catch (error) {
          console.error('error', error);
          return SkillActions.updateSkillFailure({ error: error as Error });
        }
      })
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore
  ) {}
}
