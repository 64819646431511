import {
  EnvironmentInjector,
  inject,
  Injectable,
  runInInjectionContext,
} from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PermissionGrantedActions } from './permission-granted.actions';
import {
  Firestore,
  Unsubscribe,
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  where,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';
import {
  UserRole,
  UserType,
  User_Permission,
  userTypeToJSON,
} from '../../../models';
import { getUserCollection } from '../../../misc/getUserCollection';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PermissionLevel } from 'src/app/shared/models/classes/user';

@Injectable()
export class PermissionGrantedEffects {
  private environmentInjector = inject(EnvironmentInjector);

  allAgenciesLoading = false;
  userIsAdmin = false;
  allAgenciesPermissions: User_Permission[] | null = null;

  loadPermissionsGranted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PermissionGrantedActions.loadPermissionsGranted),
      mergeMap(({ userId, userType, grantedUserType }) => {
        return new Observable<Action>(subscriber => {
          console.log('loadPermissionsGranted', {
            userId,
            userType,
            grantedUserType,
          });

          if (this.authService.userInfo?.claims['reportingSAD']) {
            if (!this.allAgenciesLoading) {
              this.allAgenciesLoading = true;
              this.userIsAdmin = true;
              getDocs(query(collection(this.firestore, 'agencies'))).then(
                agencies => {
                  const permissionList: User_Permission[] = [];

                  for (const doc of agencies.docs) {
                    const data = doc.data();

                    if (data['name']) {
                      permissionList.push({
                        active: true,
                        permissions: {},
                        roles: {
                          admin: true,
                        },
                        user: {
                          ...data,
                          userRole: UserRole.admin,
                          canImpersonate: true,
                          id: doc.id,
                          userType: UserType.AGENCY,
                        },
                        granterUserId: doc.id,
                        granterUserType: UserType.AGENCY,
                        grantedUserId: userId,
                        grantedUserType,
                      });
                    }
                  }

                  this.allAgenciesPermissions = permissionList;

                  console.log(
                    'admin permissionList',
                    this.allAgenciesPermissions
                  );
                  subscriber.next(
                    PermissionGrantedActions.loadPermissionsGrantedSuccess({
                      permissionsGranted: permissionList,
                    })
                  );
                }
              );
            } else if (this.allAgenciesPermissions) {
              console.log(
                'allAgenciesPermissions',
                this.allAgenciesPermissions
              );
              subscriber.next(
                PermissionGrantedActions.loadPermissionsGrantedSuccess({
                  permissionsGranted: this.allAgenciesPermissions,
                })
              );
            }
          }

          let collectionName = 'users';
          if (grantedUserType) {
            const collectionNameString = getUserCollection(grantedUserType);
            if (collectionNameString) {
              collectionName = collectionNameString;
            }
          }
          // let collectionName = 'users';
          // switch (userType) {
          //   case UserType.CONSUMER:
          //     collectionName = 'consumers';
          //     break;
          //   case UserType.AGENT:
          //     collectionName = 'agents';
          //     break;
          // }

          // const unsubscribe = onSnapshot(
          //   doc(
          //     this.firestore,
          //     'users',
          //     userId,
          //     'private',
          //     'permissionsGranted'
          //   ),
          //   snapshot => {
          //     if (!snapshot.exists()) {
          //       subscriber.next(
          //         PermissionGrantedActions.loadPermissionsGrantedSuccess({
          //           permissionsGranted: [],
          //         })
          //       );
          //       return;
          //     }
          //     const permissionsGranted = User_PermissionContainer.fromJSON(
          //       snapshot.data()
          //     );

          //     const permissionsGrantedList: User_Permission[] = [];
          //     for (const i in permissionsGranted.permissions) {
          //       permissionsGrantedList.push({
          //         ...permissionsGranted.permissions[i],
          //         user: {
          //           ...permissionsGranted.permissions[i].user,
          //           id: i,
          //         },
          //       });
          //     }
          //     subscriber.next(
          //       PermissionGrantedActions.loadPermissionsGrantedSuccess({
          //         permissionsGranted: permissionsGrantedList,
          //       })
          //     );
          //   },
          //   error => {
          //     subscriber.next(
          //       PermissionGrantedActions.loadPermissionsGrantedFailure({
          //         error,
          //       })
          //     );
          //   }
          // );

          let unsubscribe: Unsubscribe | undefined;
          runInInjectionContext(this.environmentInjector, () => {
            let ref = query(
              collection(
                this.firestore,
                collectionName,
                userId,
                'private',
                'permissions',
                'received'
              )
            );

            if (userType) {
              ref = query(
                ref,
                where('userType', '==', userTypeToJSON(userType))
              );
            }

            unsubscribe = onSnapshot(
              ref,
              snapshot => {
                console.log('snapshot', snapshot);
                const permissionList = snapshot.docs.map(doc => {
                  const permission = User_Permission.fromJSON({
                    ...doc.data(),
                    userId: doc.id,
                    granterUserId: doc.id,
                    grantedUserId: userId,
                    grantedUserType,
                  });

                  if (grantedUserType) {
                    permission.granterUserType = grantedUserType;
                  }
                  if (!permission.granterUserType && permission.user?.userType) {
                    permission.granterUserType = permission.user.userType
                  }

                  if (
                    this.authService.userInfo?.type &&
                    this.authService.userInfo?.type !== UserType.AGENT &&
                    permission.user &&
                    !permission.invitedAt
                  ) {
                    permission.user.canImpersonate = true;
                  }

                  return permission;
                });

                if (
                  this.authService.userInfo?.user?.uid &&
                  (this.authService.userInfo.type === UserType.AGENCY ||
                    this.authService.userInfo.type === UserType.PUBLISHER)
                ) {
                  permissionList.push({
                    active: true,
                    granterUserId: this.authService.userInfo.user.uid,
                    granterUserType: this.authService.userInfo.type,
                    grantedUserId: this.authService.userInfo.user.uid,
                    grantedUserType: this.authService.userInfo.type,
                    permissions: {
                      all: PermissionLevel.PL_WRITE,
                    },
                    user: {
                      canImpersonate: true,
                      id: this.authService.userInfo.user.uid,
                      userType: this.authService.userInfo.type,
                    },
                  });
                }

                console.log('permissionList', permissionList);

                subscriber.next(
                  PermissionGrantedActions.loadPermissionsGrantedSuccess({
                    permissionsGranted: permissionList,
                  })
                );
              },
              error => {
                console.error('error loading permissions', {
                  error,
                  userId,
                  userType,
                  collectionName,
                  grantedUserType,
                });
                subscriber.next(
                  PermissionGrantedActions.loadPermissionsGrantedFailure({
                    error,
                  })
                );
              }
            );
          });

          // Provide a way of canceling and disposing the listener
          // return unsubscribe;
          return unsubscribe;
        }).pipe(
          catchError(error =>
            of({
              type: '[PermissionGranted API] Load permissionsGranted Error',
              error,
            })
          )
        );
      })
    );
  });

  loadPermissionGranted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PermissionGrantedActions.loadPermissionGranted),
      mergeMap(({ grantedUserId, granterUserId, granterUserType }) => {
        return new Observable<Action>(subscriber => {
          console.log('loadPermissionGranted', {
            grantedUserId,
            granterUserId,
            granterUserType,
          });

          let collectionName = 'users';
          if (granterUserType) {
            const collectionNameString = getUserCollection(granterUserType);
            if (collectionNameString) {
              collectionName = collectionNameString;
            }
          }

          const ref = doc(
            this.firestore,
            collectionName,
            grantedUserId,
            'private',
            'permissions',
            'received',
            granterUserId
          );

          const unsubscribe = onSnapshot(
            ref,
            snapshot => {
              if (!snapshot.exists()) {
                subscriber.next(
                  PermissionGrantedActions.loadPermissionGrantedSuccess({
                    permissionGranted: null,
                  })
                );
                return;
              }

              const permissionList = User_Permission.fromJSON({
                ...snapshot.data(),
                userId: snapshot.id,
                grantedUserId,
                granterUserId,
                granterUserType,
              });

              subscriber.next(
                PermissionGrantedActions.loadPermissionGrantedSuccess({
                  permissionGranted: permissionList,
                })
              );
            },
            error => {
              console.log('error', error);
              subscriber.next(
                PermissionGrantedActions.loadPermissionGrantedFailure({
                  error,
                })
              );
            }
          );

          return unsubscribe;
        });
      })
    );
  });

  // addPermissionGranted$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(PermissionGrantedActions.addPermissionGranted),
  //     mergeMap(async ({ consumerId, permissiongranted }) => {
  //       try {
  //         const docRef = await addDoc(
  //           collection(this.firestore, 'consumers', consumerId, 'permissionsGranted'),
  //           permissiongranted
  //         );
  //         return PermissionGrantedActions.addpermissionsGranteduccess({
  //           permissiongranted: PermissionGranted.fromJSON({ ...permissiongranted, id: docRef.id }),
  //         }); // return new permissiongranted with id
  //       } catch (error) {
  //         return PermissionGrantedActions.addPermissionGrantedFailure({ error });
  //       }
  //     })
  //   );
  // });

  // removePermissionGranted$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(PermissionGrantedActions.removePermissionGranted),
  //     mergeMap(async ({ consumerId, permissiongrantedId }) => {
  //       try {
  //         await deleteDoc(
  //           doc(
  //             this.firestore,
  //             'consumers',
  //             consumerId,
  //             'permissionsGranted',
  //             permissiongrantedId
  //           )
  //         );
  //         return PermissionGrantedActions.removepermissionsGranteduccess({ permissiongrantedId }); // return removed permissiongranted's id
  //       } catch (error) {
  //         return PermissionGrantedActions.removePermissionGrantedFailure({ error });
  //       }
  //     })
  //   );
  // });

  // updatePermissionGranted$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(PermissionGrantedActions.updatePermissionGranted),
  //     mergeMap(async ({ consumerId, permissiongrantedId, permissiongranted }) => {
  //       try {
  //         await setDoc(
  //           doc(
  //             this.firestore,
  //             'consumers',
  //             consumerId,
  //             'permissionsGranted',
  //             permissiongrantedId
  //           ),
  //           deleteUndefined(permissiongranted),
  //           {
  //             merge: true,
  //           }
  //         );
  //         return PermissionGrantedActions.updatepermissionsGranteduccess({
  //           consumerId,
  //           permissiongrantedId,
  //           permissiongranted,
  //         }); // return updated permissiongranted's id and changes
  //       } catch (error) {
  //         return PermissionGrantedActions.updatePermissionGrantedFailure({ error });
  //       }
  //     })
  //   );
  // });

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private firestore: Firestore
  ) {}
}
